<template>
  <div class="container">
    <div class="commerce__wrapper screen__wrapper">
      <CardCommerce
        v-for="renter in renters"
        :key="renter.id"
        :renter="renter"
        @click.native="info(renter)"
      />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import CardCommerce from '@/views/Renters/Card'
import InfoRenter from '@/views/Renters/Info'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: "FavoriteRentersCommerce",
  components: {
    CardCommerce,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    renters() {
      return this.$store.getters.rentersFavCom;
    },
    hasMoreResults () {
      return this.renters.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters.paginationFavRentersCom.total
    }, 
    page() {
      return this.$store.getters.pageFavRentersCom
    },
    class() {
      return this.$route.meta.class
    }
  },
  methods: {
    info(renter) {
      const style = this.$modal.styles
      this.$modal.show(
        InfoRenter, 
        {renter}, 
        {...style, name: 'InfoRenter'},
        {'before-open': this.$modal.open,'before-close': this.$modal.close}
        );
    },
    async loadObj() {
      this.isLoading = true
      try {
        await this.$store.dispatch("fetchFavoritesRenters", 'commerce')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
  },
  // beforeDestroy() {
  //   if (Object.keys(this.$store.getters.sortParamsRenters).length !== 0 || Object.keys(this.$store.getters.filterParamsRenters).length !== 0) {
  //     this.$store.commit('filterParamsRenters', {})
  //     this.$store.commit('filterParamsRentersActive', 0)
  //     this.$store.commit('sortParamsRenters', {})
  //     this.$store.commit('resetFavRenters', 'commerce')
  //     this.loadObj()
  //   }
  // },
};
</script>
